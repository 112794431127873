/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {useState, useEffect} from 'react';
import classnames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import "../scss/styles.scss";
import Footer from './footer';

const siteMetadata = graphql`
    query SiteTitleQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

const Layout = ({ children, dark = false }) => {
    const [fixed, setFixed] = useState(false);
    const [hasScrollbars, setHasScrollBars] = useState(false);
    useEffect(() => {
        // if (typeof window === 'undefined') return;
        if (window.Modernizr && window.Modernizr.hiddenscroll) {
            setHasScrollBars(false);
        } else {
            setHasScrollBars(true);
        }
    }, []);
    return (
        <StaticQuery query={siteMetadata}>
            {data => (
                <div className={classnames('site', {fixed, hasScrollbars})}>
                    <Header siteTitle={data.site.siteMetadata.title} dark={dark} setFixed={setFixed} fixed={fixed} />
                    <main>{children}</main>
                    <Footer/>
                </div>
            )}
        </StaticQuery>
    );
};

export default Layout;
