import React from 'react';
import FindawayLogo from '../svg/fw-logo-alt.svg';
import { Link } from 'gatsby';

const Footer = () => (
    <footer>
        <div className="container">
            <div className="row mb-5">
                <div className="col-xs-12 col-md-2">
                    <Link className="d-block mb-4" to="/">
                        <FindawayLogo width={175} height={35.18} />
                    </Link>
                    <p>&copy; {new Date().getFullYear()} Findaway</p>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
