import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import FindawayLogo from '../svg/fw-logo-alt.svg';

const Header = ({ siteTitle = '', dark = false, setFixed }) => {
    return (
        <header
            className={classnames('site-header', {
                'dark-header': dark
            })}
        >
            <div className="container">
                <div className="row middle-xs">
                    <div className="col-xs-11">
                        <Link to="/">
                            <FindawayLogo width="164" height="33.03" />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
